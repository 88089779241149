import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router";
import env from "helpers/env";
import { useRouteToExternalWithQS } from "hooks/useRouteToExternal";

export function useRedirectToParkerRegister() {
  const { routeToExternalWithQS } = useRouteToExternalWithQS();
  const { cacheQueryParams } = useCacheQueryParams();

  useEffect(() => {
    cacheQueryParams();
    routeToExternalWithQS(`${env("PUBLIC_PARKER_URL")}/register`);
  }, []);
}

function useCacheQueryParams() {
  const location = useLocation();
  const queryObj = queryString.parse(location.search);

  function cacheQueryParams() {
    const {
      irclickid, utm_source, utm_medium, utm_campaign, utm_content, utm_id
    } = queryObj;

    const utmParams = {
      utm_source, utm_medium, utm_campaign, utm_content, utm_id
    };

    if (irclickid) {
      localStorage.setItem("irclickid", irclickid as string);
    }

    localStorage.setItem("utm_params", JSON.stringify(utmParams));
  }

  return { cacheQueryParams };
}
