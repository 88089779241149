import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { routeToExternal } from "helpers/routeToExternal";

export function useRouteToExternalWithQS() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  function routeToExternalWithQS(route: string) {
    routeToExternal(`${route}?${queryString.stringify(queryParams)}`);
  }

  return { routeToExternalWithQS };
}
